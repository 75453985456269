header {
  position: relative;
  height:480px;
  display:flex;
  align-items: center;
  @include media-breakpoint-down(md) {
    height:420px;
  }
  @include media-breakpoint-down(sm) {
    height:auto;
    padding:5em 0 4em;
  }
  h1, .h1 {
    color:$white;
  }
  &:after {
    content:'';
    z-index:-1;
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-color: rgba(0,0,0,0.1);
  }
  .subtitle {
    font-size:21px;
    color:$white;
    display: block;
  }
  .inner-header {
    h1 {
      max-width:780px;
    }
    &.text-center {
      h1 {
        margin-left:auto;
        margin-right:auto;
      }
    }
  }
}