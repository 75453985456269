// Contact Form 7 Styles
.wpcf7 {
    width: 100%;
    max-width: 650px;
    margin: 3rem 0;
    
    .contact-form {

        p {
            margin: 0 0 1.5rem;
            position: relative;
        }

        // Common form control styles
        .wpcf7-form-control-wrap {
            display: block;
            margin-bottom: 0.5rem;
        }

        .form-control,
        .wpcf7-form-control:not(.wpcf7-submit) {
            width: 100%;
            height: 48px;
            padding: 15px;
            font-size: 1.5rem;
            line-height: 1.5;
            font-family: $ff-default;
            color: $black;
            background-color: $light;
            border: 2px solid transparent;
            border-radius: $border-radius;
            transition: all 0.2s ease-in-out;

            &:focus {
                outline: none;
                border-color: $blue;
                background-color: $white;
                box-shadow: 0 0 0 4px rgba($blue, 0.1);
            }

            &::placeholder {
                color: rgba($gray, 0.7);
                font-size: 1.5rem;
            }

            &.wpcf7-not-valid {
                border-color: $orange;
                background-color: rgba($orange, 0.05);
            }
        }

        // Textarea specific styles
        textarea.form-control,
        textarea.wpcf7-form-control {
            height: auto;
            min-height: 150px;
            resize: vertical;
            padding: 1.25rem;
        }

        // Submit button styles
        .wpcf7-submit {
            
            border: none;
            
            &:hover {
                background-color: $blue-shade;
                transform: translateY(-2px);
                box-shadow: 0 4px 12px rgba($blue, 0.15);
            }

            &:active {
                background-color: $blue-dark;
                transform: translateY(0);
            }
        }

        // Spinner styles
        .wpcf7-spinner {
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            background-color: $blue;
        }
    }

    // Form validation styles
    .wpcf7-not-valid-tip {
        display: block;
        margin-top: 0.5rem;
        color: $orange;
        font-size: 0.875rem;
        font-family: $ff-default;
    }

    .wpcf7-response-output {
        width: 100%;
        margin: 2rem 0 0;
        padding: 1rem 1.25rem;
        border-radius: $border-radius;
        border: 2px solid transparent;
        font-family: $ff-default;
        font-size: 1rem;
        text-align: center;

        &.wpcf7-validation-errors,
        &.wpcf7-spam-blocked {
            background-color: rgba($orange, 0.1);
            border-color: $orange;
            color: $orange;
        }

        &.wpcf7-mail-sent-ok {
            background-color: rgba($blue, 0.1);
            border-color: $blue;
            color: $blue;
        }
    }
}
