footer {
  background:$blue;
  padding-top:80px;
  padding-bottom:80px;
  @include media-breakpoint-down(md) {
    padding-top:60px;
    padding-bottom:60px;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom:30px;
  }
  .logo {
    display:block;
    width:100%;
    margin-bottom:60px;
    img {
      max-width:300px;
    }
  }
  .h3 {
    display:block;
    margin-bottom:24px;
    font-size:21px;
    color:$white;
    font-weight:700;
    @include media-breakpoint-down(sm) {
      margin-bottom:16px;
    }
  }
 
  ul {
    @include media-breakpoint-down(sm) {
      padding-bottom:30px;
    }
    li {
      padding-bottom:20px;
      @include media-breakpoint-down(sm) {
        padding-bottom:12px;
      }
      a {
        font-size:20px;
        color:$white;
        font-weight:500;
        @include media-breakpoint-down(sm) {
          font-size:18px;
        }
        &:hover {
          color:$orange;
          text-decoration: underline;
        }
      }
    }
  }
}

.copyright {
  background:$blue-shade;
  padding-top:24px;
  padding-bottom:24px;
  color:$white;
  @include media-breakpoint-down(sm) {
    .col-12 {
      display:flex;
      flex-direction: column;
      & > * {
        padding-bottom:15px;
      }
    }
  }
  a {
    text-decoration: underline;
    // margin-left:70px;
    @include media-breakpoint-down(sm) {
      margin-left:0;
    }
    &:hover {
      color:$orange;
    }
  }
  .col-12 {
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    @include media-breakpoint-down(sm) {
      align-items: flex-start;
    }
    .links {
      flex: 1 0 auto;
      padding: 0 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      @include media-breakpoint-down(sm) {
        padding: 15px 0 20px;
      }
    }
  }
  .sublogos {
    img {
      height: 80px;
    }
  }
}