.read-more {
  display:inline-block;
  margin-top:26px;
  color:$orange;
  font-weight: 500;
  font-size:16px;
  i {
    padding-left:20px;
  }
  &:hover {
    text-decoration: underline;
    color:$orange;
  }
}
.column__buttons {
  display: flex;
  gap: 5px;
}
.button {
  display: inline-block;
  background-color: $orange;
  color: white;
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s;
  margin-top: 15px;
  i {
    padding-left: 10px;
  }
  &:hover {
    background-color: lighten($orange, 8%);
    color: $white;
  }
  &--blue {
    background-color: $blue;
    &:hover {
      background-color: lighten($blue, 8%);
    }
  }
}