.block--cards {
  padding-top:80px;
  padding-bottom:80px;
  overflow:hidden;
  h2 {
    position: relative;
    z-index:2;
    .bg-title {
      position:absolute;
      bottom:30px;
      left:15px;
      color:$white;
      font-size:120px;
      opacity:0.5;
      font-weight:800;
      z-index:-1;
    }
  }
  .card {
    display:inline-flex;
    min-height:100%;
    width:100%;
    background:$white;
    padding:40px;
    border-color:$blue-shade;
    border-radius:$border-radius;
    transition:0.1s ease-out;
    @include media-breakpoint-down(sm) {
      padding:30px;
    }
    .card--title {
      color:$blue;
      margin-bottom:10px;
    }
    p {
      flex:1 1 auto;
    }
    .vacature-info {
      display:flex;
      justify-content: space-between;
      flex-wrap:wrap;
      flex:1 1 auto;
      margin-bottom:-10px;
      // @include media-breakpoint-down(sm) {
      //   flex-direction: column;
      // }
      span {
        display:flex;
        align-items:flex-start;
        color:$blue;
        font-size:16px;
        margin-bottom:6px;
        &:first-child {
          i {
            border-bottom: 2px solid $blue;
          }
        }
        i {
          margin-right:6px;
        }
        span {
          font-size:14px;
          &.location {
            padding-right:30px;
          }
        }
      }
    }
    &.member {
      padding:0;
      border:0;
      figure {
        position: relative;
        padding-bottom:100%;
        img {
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          object-fit:cover;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
      }
      .member-content {
        padding:40px;
        @include media-breakpoint-down(sm) {
          padding:30px;
        }
        .social {
          display: block;
          margin-top: 10px;
          color:$blue;
          font-size:28px;
          a:hover {
            color:$orange;
          }
        }
      }
    }
  }
  a.card:hover {
    cursor:pointer;
    transition: 0.05s ease-in;
    background:$blue-dark;
    .card--subtitle, p {
      color:$white;
    }
    .read-more {
      text-decoration: underline;
    }
    .vacature-info {
      span {
        color:$white;
        &:first-child {
          i {
            border-color:$white;
          }
        }
      }
    }
  }
}