/*html*/
.lock-scroll {
  &,
  body {
    overflow-x: hidden;
  }
}

/**
 * Text hyphenation
 *
 * Break strings when their length exceeds the width of their container
 * and hyphenate words at the end of lines using the `hyphens` property.
 * Browser support for hyphenation: http://caniuse.com/#search=hyphenation
 */
 .h-text-hyphenate {
  word-wrap: break-word;
  hyphens: auto;
}