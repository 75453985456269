html {
  // Font size is 1 rem == 10 pixels
  font-size:calc(1em * .625);
  @extend %ff-default;
  font-weight:400;
  // &.wf-active {
  //   @extend %ff-default;
  // }
  
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem; // 18 px
  font-weight: 500;
  color:$blue-shade;
  @extend %ff-default;
  letter-spacing: -0.015em;
}

p, li {
  line-height:26px;
}

a:hover {
  text-decoration: none;
}

// Global Typography Styles
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .title {
  display:block;
  font-size:32px;
  color:$blue-shade;
  margin-bottom:16px;
}



h1, .h1 {
  font-weight:800;
  @include media-breakpoint-down(md) {
    font-size:42px;
    line-height:42px;
  }
  @include media-breakpoint-down(sm) {
    font-size:24px;
    line-height:28px;
  }
}
.inner-header {
  h1, .h1 {
    font-size: 42px;
    @include media-breakpoint-down(sm) {
      font-size:24px;
      line-height: 28px;
    }
  }
}
h2, .h2, .title {
  font-size:28px;
  line-height:35px;
  font-weight:800;
}

h2, .h2 {
  font-weight:700;
}

h3, .h3 {
  font-size:21px;
  line-height:24px;
  font-weight:700;
} 

