#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  z-index: 29;
  display: none;
}

.navbar {
  position: fixed;
  background:$light;
  z-index:30;
  height:80px;
  top:0;
  width:100%;
  .logo {
    display:flex;
    height:100%;
    img {
      max-height:32px;
      @include media-breakpoint-down(md) {
        height:40px;
      }
    }
  }
  .nav-toggle {
    position: absolute;
    z-index:31;
    right: 0;
    top: -5px;
    padding: 20px;
    font-size: 32px;
  }
  nav {
    float:right;
    & > ul {
      display:flex;
      & > li {
        padding-left:30px;
        @include media-breakpoint-down(lg) {
          padding-left:20px;
        }
        &:hover {
          ul, .sub-menu-bg {
            display:block;
          }
          & > a {
            color:$gray;
            &:before {
              display:block;
            }
            &:hover {
              color:$blue;
            }
          }
        }
       
        & > a {
          display:inline-block;
          position: relative;
          font-family: $ff-default;
          @extend %fw-medium;
          color:$gray;
          font-size:17px;
          padding-bottom:5px;
          @include media-breakpoint-down(lg) {
            font-size:16px;
          }
        }

        &.active {
          & > a {
            color:$orange;
            &:before {
              display:block;
            }
          }
        }

        &.menu-item-has-children {
          padding-right:20px;
          position: relative;
          & > a {
            font-size:17px;
            &:after {
              content: '\f078';
              font-family: 'Font Awesome 5 Pro';
              color: $gray;
              position: absolute;
              right: -20px;
              font-size:15px;
              font-weight: 400;
            }
          }
        } 

        // Submenu
        ul {
          z-index:2;
          position: absolute;
          background:$light;
          display:none;
          width: max-content;
          left: 0;
          padding: 30px 30px 10px;
          // width: calc(100% - 30px);
          box-sizing: content-box;
          & > li {
            a {
              display:inline-block;
              position: relative;
              font-family: $ff-default;
              color:$gray;
              font-size:17px;
              padding-bottom:10px;
              &:hover {
                color:$blue;
              }
            }
          }
        }

      }
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      top:0;
      left:0;
      height:100%;
      width:100%;
      background:$light;
      & > ul {
        padding-top:10px;
        display:flex;
        flex-direction: column;
        & > li {
          position: relative;
          padding-top:12px;
          padding-bottom:10px;
          // border-bottom:2px solid $gray;
          &:hover ul {
            display:none;
          }
          & > a {
            font-size:18px;
            display:inline-block;
            &:after {
              display:none !important;
            }
          }
          & > ul {
            position: relative;
            margin-top:20px;
            padding-top:0;
          }
        }
      };
    }

    .mobile-subpull {
      color: $gray;
      position: absolute;
      right: 10px;
      top: 5px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      i {
        font-size: 20px;
      }
    }

  }
}