/*
 * Helpers
 */

/*
 * Clearfix: contain floats
 * http://cssmojo.com/latest_new_clearfix_so_far/
 */
 %cf {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */
%hidden {
  display: none !important;
  visibility: hidden;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
%visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}


/**
 * Typo
 */

%fw-light {
  font-weight: 300;
}

%fw-normal {
  font-weight: 400;
}

%fw-medium {
  font-weight: 500;
}

%fw-semibold {
  font-weight: 600;
}

%fw-bold {
  font-weight: 700;
}

%fw-extrabold {
  font-weight: 800;
}

%fw-black {
  font-weight: 900;
}

%fs-normal {
  font-style: normal;
}

%fs-italic {
  font-style: italic;
}

%text-left {
  text-align: left;
}

%text-center {
  text-align: center;
}

%text-right {
  text-align: right;
}

%text-justify {
  text-align: justify;
}

%text-uppercase {
  text-transform: uppercase;
}

%text-notransform {
  text-transform: none;
}

%text-nodecoration {
  text-decoration: none;
}

%list-nostyle {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%button-nostyle {
  background: transparent;
  border: 0;
  padding: 0;
}

%link-nostyle {
  color: inherit;
  text-decoration: none;
}

/*
 * Colors
 */
%color-white {
  color: $white;
}

%color-black {
  color: $black;
}

%color-dark {
  color: $color-dark;
}

%color-light {
  color: $color-light;
}

%light {
  background-color: $white;
  color: $color-dark;
}

%dark {
  background-color: $color-dark;
  color: $color-light;
}

/**
 * Project specific
 */

%ff-default {
  font-family: $ff-default;
}

%ff-alt {
  font-family: $ff-alt;
}

%ff-fallback {
  font-family: $ff-fallback;
}