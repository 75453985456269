.container--content,
.columns {
  padding-top: 50px;
  padding-bottom: 30px;

  @include media-breakpoint-down(xs) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  p {
    margin-bottom: 1em;

    a {
      color: #ff6200;

      &:hover {
        color: #b34500;
      }
    }
  }

  h2,
  h3,
  h4,
  .h2,
  .h3,
  .h4 {
    margin-top: 1em;
    margin-bottom: 0;
  }

  ul {
    margin-left: 1em;
    margin-bottom: 20px;
    list-style-type: disc;

    li {
      a {
        color: #ff6200;

        &:hover {
          color: #b34500;
        }
      }
    }
  }

  ol {
    margin-left: 0;
    margin-bottom: 20px;
    list-style: none;

    li {
      counter-increment: ol-counter;

      &:before {
        content: counter(ol-counter) ". ";
        font-size: 21px;
        font-weight: 700;
        display: inline;
      }

      h3 {
        display: inline;
      }

      a {
        display: inline-block;
        color: #ff6200;

        &:hover {
          color: #b34500;
        }
      }
    }
  }

  figure {
    margin-top: -20px;
    margin-bottom: 40px;

    img {
      border-radius: $border-radius;
    }
  }
}

.block--inner {
  p {
    margin-bottom: 20px;

    a {
      color: #ff6200;

      &:hover {
        color: #b34500;
      }
    }
  }
}

.wp-block-columns {
  margin-bottom: 0 !important;
}

.banner {
  position: relative;
  background: #007bc7;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .col-12 * {
    position: relative;
    z-index: 2;
    color: $white;
  }
}