body {
  background:$white;
}

img {
  max-width:100%;
}

.img-fill {
  position: absolute;
  top:0;
  left:0;
  z-index:-2;
  width:100%;
  height:100%;
  img {
    position: absolute;
    width:100%;
    height:100%;
    object-fit: cover;
  }
}

.layoutArea td {
  vertical-align: top;
}
.layoutArea tr td {
  border-bottom: 2px solid #eee;
}