.block--center {
  text-align: center;
  padding:40px 30px;
  @include media-breakpoint-down(xs) {
    padding:40px 30px;
  }
  @include media-breakpoint-down(sm) {
    .title {
      font-size:21px;
      line-height:30px;
    }
  }
  
}

.block--row {
  padding:60px 140px;
  @include media-breakpoint-down(md) {
    padding:60px 120px;
  }
  @include media-breakpoint-down(xs) {
    padding:40px 0;
  }
  &.block--row--col_img {
    .row {
      flex-direction: row-reverse !important;
      .right-block {
        .inner-img {
          margin-left: auto;
          margin-right: -50px;
          z-index: 2;
        }
      }
      .left-block {
        @include media-breakpoint-down(xs) {
          padding:60px 30px;
          .block--inner {
            padding-right:0;
          }
        }
      }
    }
  }
  .left-block {
    position: relative;
    padding:100px 100px 100px 0;
    box-sizing: content-box;
    .block--inner {
      position: relative;
      z-index:2;
      padding-right:30px;
    }
    @include media-breakpoint-down(md) {
      padding:100px 10% 100px 0;
    }
    @include media-breakpoint-down(sm) {
      padding:80px 10% 60px 0;
    }
    @include media-breakpoint-down(xs) {
      padding:60px 30px;
      .block--inner {
        padding-right:0;
      }
    }
    &:before {
      content:'';
      position: absolute;
      z-index: 1;
      top:-40px;
      left:0;
      background:$orange;
      border-radius:100px;
      width:6px;
      height:80px;
      @include media-breakpoint-down(xs) {
        left:30px;
        height:60px;
        top:-30px;
      }
    }
    &:after {
      content:'';
      position: absolute;
      z-index:0;
      top:0;
      left:-15%;
      border-radius:$border-radius;
      width:calc(100% + 200px);
      height:80%;
      background:$white;
      @include media-breakpoint-down(md) {
        width:calc(100% + 100px);
      }
      @include media-breakpoint-down(xs) {
        left:0;
        width:100%;
      }
    }
  }
  .right-block, .left-block {
    height:100%;
    padding:70px 0;
    .inner-img {
      position: relative;
      margin-left:-50px;
      height:100%;
      img {
        position: absolute;
        width:100%;
        height:100%;
        border-radius:$border-radius;
        object-fit: cover;
      }
    }
  }
  &.block--row--col_text {
    .row {
      flex-direction: row-reverse;
      .left-block {
        padding:100px 0 100px 100px;
        &:before {
          left:100px;
        }
        @include media-breakpoint-down(md) {
          padding:100px 10% 100px 0;
          &:before {
            left:0;
          }
        }
        @include media-breakpoint-down(sm) {
          padding:80px 10% 60px 0;
        }
        @include media-breakpoint-down(xs) {
          padding:60px 30px;
          &:before {
            left:30px;
          }
          .block--inner {
            padding-right:0;
          }
        }
      }
    }
  }
}