.contact {
  figure {
    position:absolute;right:0;width:55%;height:100%;
    @include media-breakpoint-down(sm) {
      width:45%;
    }
    @include media-breakpoint-down(xs) {
      display: block;
      position: relative;
      width:100%;
      padding-bottom:50px;
      img {
        border-radius:$border-radius;
      }
    }
    img {
      height:100%;width:100%;object-fit: cover;
    }
  }
  ul {
    li {
      a {
        &:hover {
          color:$orange;
        }
      }
    }
  }
}